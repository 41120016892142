<template>
  <div class="">
    <FullWidthBanner/>

    <SectionTitle title="Casino Live">
      <div class="pb-4 pt-16 px-6 shadow-xl" slot="filters">
        <div class="inline-flex items-center divide-x divide-white">
          <a href="#" class="uppercase text-xs font-semibold px-12 hover:text-primary">Più Popolari</a>
          <a href="#" class="uppercase text-xs font-semibold px-12 hover:text-primary">Live Roulette</a>
          <a href="#" class="uppercase text-xs font-semibold px-12 hover:text-primary">Live Blackjack</a>
          <a href="#" class="uppercase text-xs font-semibold px-12 hover:text-primary">Live Baccarat</a>
          <a href="#" class="uppercase text-xs font-semibold px-12 hover:text-primary">Live Poker</a>
          <a href="#" class="uppercase text-xs font-semibold px-12 hover:text-primary">Tutti i Giochi</a>
        </div>
      </div>
    </SectionTitle>

    <section class="w-full mt-16">
      <div class="px-16 grid gap-12 grid-cols-3">
        <div class="relative flex-1 " v-for="(game, index) in games" :key="index">
          <div class="mb-1 py-2 px-4 w-full flex items-center justify-between rounded-t-lg bg-gradient-to-r from-primary via-blue-800 to-transparent">
            <h3 class="font-semibold text-white">Live</h3>

            <span>{{ game.betting }}</span>
          </div>
          <a :href="game.gameUrl" class="mt-1 w-full bg-gray-800 cursor-pointer group">
            <custom-image :original="game.image" class-list="w-full rounded-b-sm"/>

            <div class="absolute w-full text-center opacity-0 group-hover:opacity-100 transition duration-300"
                 style="bottom: 5%">
              <button class="uppercase bg-primary text-black text-md font-semibold rounded-full px-8 py-1">Gioca Ora
              </button>
            </div>
          </a>
        </div>
      </div>

      <div class="w-full flex items-center justify-center gap-5 mt-10">
        <button class="h-full focus:outline-none text-white hover:text-primary">
          <svg class="w-8 h-8 stroke-current" fill="none" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2} d="M15 19l-7-7 7-7"/>
          </svg>
        </button>

        <p class="text-sm"><span>1</span> di <span>4</span></p>

        <button class="h-full text-primary">
          <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2} d="M9 5l7 7-7 7"/>
          </svg>
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import FullWidthBanner from "@/components/FullWidthBanner";
import GamesListingSection from "@/components/GamesListingSection";
import SectionTitle from "@/components/SectionTitle";

export default {
  components: {
    FullWidthBanner,
    GamesListingSection,
    SectionTitle
  },

  data() {
    return {
      games: [
        {
          image: '/img/live-casino/baccarat.jpg',
          gameUrl: '#',
          name: "Baccarat",
          betting: '0,25€ - 1.000€'
        },
        {
          image: '/img/live-casino/baccarat.jpg',
          gameUrl: '#',
          name: "Baccarat",
          betting: '0,25€ - 1.000€'
        },
        {
          image: '/img/live-casino/baccarat.jpg',
          gameUrl: '#',
          name: "Baccarat",
          betting: '0,25€ - 1.000€'
        },
        {
          image: '/img/live-casino/baccarat.jpg',
          gameUrl: '#',
          name: "Baccarat",
          betting: '0,25€ - 1.000€'
        },
        {
          image: '/img/live-casino/baccarat.jpg',
          gameUrl: '#',
          name: "Baccarat",
          betting: '0,25€ - 1.000€'
        },
        {
          image: '/img/live-casino/baccarat.jpg',
          gameUrl: '#',
          name: "Baccarat",
          betting: '0,25€ - 1.000€'
        },
        {
          image: '/img/live-casino/baccarat.jpg',
          gameUrl: '#',
          name: "Baccarat",
          betting: '0,25€ - 1.000€'
        },
        {
          image: '/img/live-casino/baccarat.jpg',
          gameUrl: '#',
          name: "Baccarat",
          betting: '0,25€ - 1.000€'
        },
        {
          image: '/img/live-casino/baccarat.jpg',
          gameUrl: '#',
          name: "Baccarat",
          betting: '0,25€ - 1.000€'
        },
        {
          image: '/img/live-casino/baccarat.jpg',
          gameUrl: '#',
          name: "Baccarat",
          betting: '0,25€ - 1.000€'
        },
        {
          image: '/img/live-casino/baccarat.jpg',
          gameUrl: '#',
          name: "Baccarat",
          betting: '0,25€ - 1.000€'
        },
        {
          image: '/img/live-casino/baccarat.jpg',
          gameUrl: '#',
          name: "Baccarat",
          betting: '0,25€ - 1.000€'
        },
      ]
    }
  }
}
</script>
